import { cx } from "@linaria/core";
import React, { FC, memo } from "react";
import { cssBoxActive, cssBoxDisabled, cssBoxImage, cssBoxText, VariationsBox, VariationsBoxInner, VariationsBoxList, VariationsSelectedName } from "@/components/Products/parts/Variations/StyledVariations";
import { VariationsViewPropsType } from "@/components/Products/parts/Variations/types";
import { EntityImage } from "@/ui/EntityImage/EntityImage";
import { Loader } from "@/ui/Loaders/ComponentUiLoader/Loader";
import { Typography } from "@/ui/Typography/Typography";
const BoxedView: FC<VariationsViewPropsType> = memo(({
  items,
  selected,
  isFetching,
  onSelect
}) => {
  return <>
        <VariationsSelectedName>
          {selected?.type}:{" "}
          <Typography variant={"span"}>{selected?.name}</Typography>
        </VariationsSelectedName>
        <VariationsBoxList>
          {isFetching && <Loader />}
          {items.map(({
        uuid = "",
        name = "",
        total_qty = 0,
        image = undefined
      }) => {
        const disabled = !total_qty;
        return <VariationsBox key={uuid} className={cx(!!image ? cssBoxImage : cssBoxText, selected?.uuid === uuid && cssBoxActive, disabled && cssBoxDisabled)} onClick={() => {
          onSelect(uuid);
        }}>
                  <VariationsBoxInner>
                    <EntityImage imagePath={image} width={40} height={40} priority />
                    <Typography variant={"p12"}>{name}</Typography>
                  </VariationsBoxInner>
                </VariationsBox>;
      })}
        </VariationsBoxList>
      </>;
});
BoxedView.displayName = "BoxedView";
export { BoxedView };
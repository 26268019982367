import { cx } from "@linaria/core";
import React, { FC, memo } from "react";
import { cssBoxDisabled, VariationsBoxInner } from "@/components/Products/parts/Variations/StyledVariations";
import { VariationsViewPropsType } from "@/components/Products/parts/Variations/types";
import { colors } from "@/styles/utils/vars";
import { EntityImage } from "@/ui/EntityImage/EntityImage";
import { Icon } from "@/ui/Icon";
import { Select } from "@/ui/Select/Select";
import { SelectPropsType } from "@/ui/Select/types";
import { Typography } from "@/ui/Typography/Typography";
const SelectView: FC<VariationsViewPropsType & Pick<SelectPropsType, "withAnimatingLabel">> = memo(({
  items,
  selected,
  onSelect,
  isFetching,
  inCart,
  withAnimatingLabel = true
}) => {
  return <Select closeMode="destroy" withAnimatingLabel={withAnimatingLabel} ariaLabel={selected?.type || "Вариация"} initialValue={selected?.uuid} items={items.map(({
    uuid = "",
    name = "",
    total_qty = 0,
    image = undefined
  }) => {
    const disabled = !total_qty;
    return {
      name: name,
      value: uuid,
      disabled: disabled,
      layout: <VariationsBoxInner className={cx(disabled && cssBoxDisabled)}>
                  <EntityImage imagePath={image} width={40} height={40} priority />
                  <Typography variant={"span"}>{name}</Typography>
                  {inCart(uuid) && <Icon NameIcon={"Check"} fill={colors.green} />}
                </VariationsBoxInner>
    };
  })} variant={"default"} isVisibleLayout onSelectValue={onSelect} isFetching={isFetching} />;
});
SelectView.displayName = "SelectView";
export { SelectView };